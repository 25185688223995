header  {
  overflow: hidden;
  padding-left: 1rem;
  padding-right: 1rem;
  background-color: #fff;
}

header .Container {
  border-radius: 0.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}

header .Title {
  color: #323232;
  font-size: 2rem;
  margin-top:2rem;
  margin-bottom: 5rem;
  font-weight: 700;
}
